import React from "react";

const Button = ({ label, classes, icon = null }) => {
  return (
    <button
      className={` tracking-wider ${classes} flex justify-center items-center gap-2`}
    >
      {label}
      {icon && <img className="w-3 lg:w-4" src={icon} alt="icon" />}
    </button>
  );
};

export default Button;

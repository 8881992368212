import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="bg-blue-600 text-white py-6 px-4 text-center">
          <h1 className="text-3xl font-bold">
            TaxSmart - Terms and Conditions
          </h1>
        </div>

        <div className="p-6 space-y-6">
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4 border-b-2 border-blue-200 pb-2">
              Services
            </h2>
            <p className="text-gray-700">
              TaxSmart provides tax calculation tools and financial insights.
              <span className="font-bold text-red-600 ml-2">
                The app does not offer financial or legal advice.
              </span>
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4 border-b-2 border-blue-200 pb-2">
              User Responsibilities
            </h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Users must provide accurate information.</li>
              <li>Unauthorized use of the app is prohibited.</li>
              <li>Users are responsible for their own financial decisions.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4 border-b-2 border-blue-200 pb-2">
              Privacy & Data Use
            </h2>
            <p className="text-gray-700">
              We respect user privacy and collect data only to enhance app
              functionality. Data is not shared with third parties without
              consent.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4 border-b-2 border-blue-200 pb-2">
              Liability Disclaimer
            </h2>
            <p className="text-gray-700">
              TaxSmart provides services "as-is." We are not responsible for
              financial losses, app downtime, or errors in calculations.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4 border-b-2 border-blue-200 pb-2">
              Updates & Changes
            </h2>
            <p className="text-gray-700">
              These terms may be updated from time to time. Continued app use
              constitutes acceptance of updates.
            </p>
          </section>

          <div className="border-t border-gray-200 pt-6 text-center">
            <div className="text-gray-600 mb-4">
              <span>Support: </span>
              <a
                href="mailto:product@upgrowthmarketing.in"
                className="ml-2 text-blue-600 hover:underline"
              >
                product@upgrowthmarketing.in
              </a>
            </div>
            <p className="text-sm text-gray-500">
              By using TaxSmart, you agree to these Terms and Conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

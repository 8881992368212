import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RouteWithMeta from "./RouteWithMeta";
import TermsAndConditions from "../pages/Terms&Condition";

const Homepage = lazy(() => import("../pages/Homepage"));
const About = lazy(() => import("../pages/About"));
const Service = lazy(() => import("../pages/Services"));
const Industry = lazy(() => import("../pages/Industry"));
const Blogs = lazy(() => import("../pages/Blogs"));
const Privacy = lazy(() => import("../pages/PrivacyPolicy"))

const domain = "";

const routes = [
    {
        path: "/",
        element: <Homepage />,
        isPrivate: false,
        meta: {
            title: "Home - Upgrowth",
            description: "Welcome to our homepage.",
            keywords: "home, website, welcome",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: "Home",
                url: `${domain}/`,
                description: "Welcome to our homepage.",
            },
        },
    },
    {
        path: "/about",
        element: <About />,
        isPrivate: false,
        meta: {
            title: "About Us - Upgrowth",
            description: "Learn more about us.",
            keywords: "about us, our company, information",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "AboutPage",
                name: "About Us",
                url: `${domain}/about`,
                description: "Learn more about our company and what we do.",
            },
        },

    },
    {
        path: "/services",
        element: <Service />,
        isPrivate: false,
        meta: {
            title: "Our Services - Upgrowth",
            description: "Explore the services we offer.",
            keywords: "services, offerings, solutions",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: "Services",
                url: `${domain}/services`,
                description: "Learn more about our company and what we do.",
            },
        },

    },
    {
        path: "/industries",
        element: <Industry />,
        isPrivate: false,
        meta: {
            title: "Industries - Upgrowth",
            description: "Discover the industries we serve.",
            keywords: "industries, sectors, business areas",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: "Industry",
                url: `${domain}/industries`,
                description: "Learn more about our company and what we do.",
            },
        },

    },
    {
        path: "/privacy-policy",
        element: <Privacy />,
        isPrivate: false,
        meta: {
            title: "Industries - Upgrowth",
            description: "Discover the industries we serve.",
            keywords: "industries, sectors, business areas",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: "Industry",
                url: `${domain}/industries`,
                description: "Learn more about our company and what we do.",
            },
        },

    },
    {
        path: "/terms-condition",
        element: <TermsAndConditions />,
        isPrivate: false,
        meta: {
            title: "Industries - Upgrowth",
            description: "Discover the industries we serve.",
            keywords: "industries, sectors, business areas",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: "Industry",
                url: `${domain}/industries`,
                description: "Learn more about our company and what we do.",
            },
        },

    },
    {
        path: "/blogs",
        element: <Blogs />,
        isPrivate: false,
        meta: {
            title: "Blogs - Upgrowth",
            description: "Discover the industries we serve.",
            keywords: "blogs, sectors, business areas",
            structuredData: {
                "@context": "http://schema.org",
                "@type": "WebPage",
                name: "Blogs",
                url: `${domain}/blogs`,
                description: "Learn more about our company and what we do.",
            },
        },

    },
]


const AllRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {routes.map(({ path, element, meta }) => (
                    <Route
                        path={path}
                        element={<RouteWithMeta element={element} meta={meta} />}
                        key={path}
                    />
                ))}
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Routes>
        </Suspense>
    );
};

export default AllRoutes;

import React, { useState } from "react";
import { IoMenu, IoClose } from "react-icons/io5";
import Button from "../components/common/Button";
import Logo from "../assets/images/Logo.svg";
import { useNavigate } from "react-router-dom";

const options = [
  { id: 1, label: "SERVICES", route: "/services" },
  // { id: 2, label: "INDUSTRIES", route: "/industries" },
  { id: 3, label: "ABOUT", route: "/about" },
  { id: 4, label: "BLOGS", route: "/blogs" },
];

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const handleNavigation = (route) => {
    navigate(route);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="flex justify-between items-center px-[1.5rem] sm:px-[3rem] fixed w-full top-0 py-8 bg-white/70 z-[9999] shadow-lg backdrop-blur-md ">
      {/* Logo */}
      <div
        className="text-2xl font-extrabold cursor-pointer hover:text-[rgb(91,91,91)] duration-100"
        onClick={() => handleNavigation("/")}
      >
        <img className="w-[160px] h-[46px]" src={Logo} alt=""/>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex gap-10 text-[15px] font-bold items-center">
        {options.map((item) => (
          <p
            key={item.id}
            className="cursor-pointer hover:text-[#2153E8] duration-200"
            onClick={() => handleNavigation(item.route)}
          >
            {item.label}
          </p>
        ))}
        <Button
          label="Boost Rankings & Revenue"
          classes="bg-white px-6 py-2 text-[15px] border-2 rounded-lg border-[#2153E8] font-bold hover:bg-[#2153E8] hover:text-white duration-200"
        />
      </div>

      {/* Mobile Navigation */}
      <div className="md:hidden flex items-center">
        <button onClick={handleMenuToggle} aria-label="Toggle navigation menu">
          {isMobileMenuOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="absolute top-[80px] md:top-[4rem] left-0 w-full bg-white z-10 shadow-lg p-5 md:hidden">
          {options.map((item) => (
            <p
              key={item.id}
              className="py-2 text-lg font-semibold text-gray-700 cursor-pointer hover:text-[#3969FC] duration-200"
              onClick={() => handleNavigation(item.route)}
            >
              {item.label}
            </p>
          ))}
          <Button
            label="WORK WITH US"
            classes=" bg-white px-6 py-2 text-xs border-2 border-[#3969FC] font-bold  hover:bg-[#f37741] hover:text-white duration-200"
          />
        </div>
      )}
    </div>
  );
};

export default Header;

import Layout from "./layout";
import AllRoutes from "./Routes";

function App() {

  return (
    <Layout>
      <div className="max-w-[1800px] mx-auto mt-[100px]">

        <AllRoutes />
      </div>
    </Layout>
  )
}

export default App;

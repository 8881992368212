import { Helmet } from "react-helmet-async";

const RouteWithMeta = ({ element, meta }) => {
    return (
        <>
            <Helmet>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                {meta.structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(meta.structuredData)}
                    </script>
                )}
            </Helmet>
            {element}
        </>
    );
};

export default RouteWithMeta;
import React from "react";
import footer_logo from "../assets/images/footer_logo.svg";

const footerData = [
  {
    title: "Paid Advertising",
    links: [
      "SEM",
      "Google Ads (SEM)",
      "Facebook Ads Agency",
      "PPC (Pay-per-click)",
      "LinkedIn Ads",
      "Paid Social Media Agency",
      "Adwords Agency",
      "Spotify Ads",
      "Hulu Ads",
      "Amazon Ads",
      "AdWords Management Agency",
    ],
  },
  {
    title: "SEO & Content Marketing",
    links: [
      "SEO Agencies",
      "Content Marketing",
      "B2B SEO Agency",
      "Content Creation Agency",
      "Content Strategy Agency",
      "Global SEO Agency",
      "Enterprise SEO",
      "Inbound Marketing Agency",
      "B2B Content Marketing",
      "Technical SEO Agency",
    ],
  },
  {
    title: "AI Transformation",
    links: [
      "YouTube Marketing Agency",
      "Ecommerce Marketing Agency",
      "SaaS Marketing Agency",
      "Conversion Rate Optimization Agency",
      "B2B SaaS Marketing Agency",
      "AI Agency",
    ],
  },
  {
    title: "Resources",
    links: [
      "Business Phone Services",
      "Ecommerce Website Builders",
      "Email Marketing Services",
      "Web Hosting Providers",
      "SEO Tools",
      "Best Marketing Agencies",
      "AI in Digital Marketing",
    ],
  },
];

const Footer = () => {
  return (
    <footer className="bg-black text-gray-300 pb-10 pt-16">
     <div className="flex justify-center sm:justify-start mb-8 px-6 sm:px-[41px] py-[12px]">
  <img src={footer_logo} alt="Footer Logo" className="h-12" />
</div>

      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-6">
        {footerData.map((section, index) => (
          <div key={index} className="space-y-4">
            <h3 className="text-lg sm:text-xl font-semibold text-white">{section.title}</h3>
            <ul className="space-y-2">
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <p className="hover:text-[#7d9dfb] cursor-pointer text-sm sm:text-base">{link}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="mt-8 text-center text-sm text-gray-500">
        © 2024 Up Growth. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
